import React from 'react'
import NoteApp from './NoteApp'

function App() {
  return (
    <div>
        <h1>CATATANKU</h1>
        <div className="container">
          <NoteApp />
        </div>
    </div>
  )
}

export default App