import React from 'react'

function NoteNull() {
  return (
    <div className='note-data'>
        <p>Catatan Kosong...</p>
    </div>
  )
}

export default NoteNull